import "../styles/header.css";
import React, { useState, useEffect } from 'react';
import hardware from '../assets/images/home/hardware.jpg';
import paints from '../assets/images/home/paints.jpeg';
import Plumbing from '../assets/images/home/plumbing.jpg';
import sanitary from '../assets/images/home/sana.jpg';
import electricals from '../assets/images/home/switch.JPG';
import { motion } from 'framer-motion';
const Header = ({ options }) => {
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  // Function to go to the next option
  const goToNextOption = () => {
    setCurrentOptionIndex((prevIndex) =>
      prevIndex === options.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Use useEffect to set up the automatic option transition
  useEffect(() => {
    const optionTimer = setInterval(goToNextOption, 3000); // Change option every 3 seconds

    // Cleanup the timer on component unmount
    return () => clearInterval(optionTimer);
  }); // The empty dependency array ensures the effect runs only once



  // Array of images
  const images = [electricals, Plumbing, sanitary, paints, hardware];

  return (
    <div className="header">
      <div>
        {options.map((option, index) => (
          <div key={index} style={{ display: index === currentOptionIndex ? 'block' : 'none' }}>
  <motion.img
            whileHover={{ scale: 1.1 }} // Add hover animation (optional)
            animate={{ opacity: currentOptionIndex === index ? 1 : 0 }}
            transition={{ duration: 1 }} // Adjust animation duration
            src={images[index]}
            alt={option.text}
            className="image"
          /> 
            <div style={{ alignItems: "center", paddingLeft: "10%", }}>
              <p className='radio_Text'>{option.text}</p>
              {/* Your radio buttons go here */}
              {options.map((_, idx) => (
                <input
                  key={idx}
                  className='radio_button'
                  type="radio"
                  checked={currentOptionIndex === idx}
                  onChange={() => setCurrentOptionIndex(idx)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Header;
