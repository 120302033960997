import React from "react";
import { motion } from "framer-motion";
import "../styles/SinglePost.css";

export default function SinglePost() {
  const titleVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: "easeOut" } },
  };

  const descVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: "easeOut", delay: 0.5 } },
  };

  return (
    <div className="singlePost">
      <header>
        <motion.h1
          className="singlePostTitle"
          initial="hidden"
          animate="visible"
          variants={titleVariants}
        >
          A VISION TO PROVIDE <br /> QUALITY PRODUCTS
        </motion.h1>
        <motion.p
          className="singlePostDesc"
          initial="hidden"
          animate="visible"
          variants={descVariants}
        >
          BUILD UP YOUR HOME WITH THE LATEST AND GREATEST PRODUCTS.
          <br />
          WE OFFER A WIDE RANGE OF PRODUCTS SUCH AS HARDWARE, PAINTS, ELECTRICAL
          ITEMS, SANITARY WARE, AND MORE. <br />
          OUR SELECTION INCLUDES EVERYTHING YOU NEED TO MAKE YOUR HOME MORE
          COMFORTABLE, AND SECURE.
        </motion.p>
      </header>
    </div>
  );
}
