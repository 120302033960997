import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import electricalImg from "../assets/images/mountain-industrial-pipe001.jpg";
import Switchgear from "../assets/images/Switch_gears.png";
import Lighting from "../assets/images/Lightings.png";
import SWITCHES from "../assets/images/air-modular-range.jpg";
import cables from "../assets/images/Cables.png";
import fans from "../assets/images/Fans01.png";
import pumps from "../assets/images/pumps.png";
import Geyser from "../assets/images/Geyser.png";
import Topbar from "./Topbar";
import "../styles/Branche.css";
import Footer from "./Footer";
const cardData = [
  { id: 1, imgSrc: SWITCHES, title: "SWITCHES", description: "Flip, Rocker Switches, Push-Button Switches, Slider Switches, Touch Switches, Smart Switches, Dimmer Switches" },
  { id: 2, imgSrc: Switchgear, title: "SWITCH GEARS", description: "MCB, RCCB, ISOLATOR, DISTRIBUTION BOARDS, PHASE SELECTOR DBS, ACCL" },
  { id: 3, imgSrc: Lighting, title: "LIGHTINGS", description: "LED BULBS, LED TUBE LIGHTS, Recessed LED, FLOOD LIGHTS, TRACK LIGHTS, String LIGHTS, SMART BULBS" },
  { id: 4, imgSrc: cables, title: "WIRES & CABLES", description: "NON-METALLIC SHEATHED CABLE, COAXIAL CABLE, TWISTED PAIR CABLE, SINGLE CONDUCTOR WIRE" },
  { id: 5, imgSrc: fans, title: "FANS", description: "CEILING FAN, TABLE FAN, PEDESTAL FAN, WALL MOUNTED FAN" },
  { id: 6, imgSrc: pumps, title: "PUMPS", description: "CENTRIFUGAL PUMPS, SUBMERSIBLE PUMPS, AXIAL-FLOW PUMPS, BOOSTER PUMPS, JET PUMPS" },
  { id: 7, imgSrc: Geyser, title: "GEYSERS", description: "CONVENTIONAL STORAGE, TANKLESS INSTANT, HEAT PUMP AND IMMERSION WATER HEATERS" },
  { id: 8, imgSrc: electricalImg, title: "CONDUITS", description: "RIGID METAL CONDUIT (RMC), INTERMEDIATE METAL CONDUIT (IMC), ELECTRICAL NON-METALLIC TUBING (ENT) AND PVC" },

];

const Branche = () => {
  const [hoveredCard, setHoveredCard] = useState(null);


  const handleMouseOver = (id) => setHoveredCard(id);
  const handleMouseOut = () => setHoveredCard(null);

  const handleClick = (id) => {
   // navigate(`/details/${id}`);
  };

  return (
    <div>
        <Topbar />
        <div  className="background-section"> 
      
      {cardData.map((card) => (
        <CardComponent
          key={card.id}
          isHovering={hoveredCard === card.id}
          onMouseOver={() => handleMouseOver(card.id)}
          onMouseOut={handleMouseOut}
          onClick={() => handleClick(card.id)}
          imgSrc={card.imgSrc}
          title={card.title}
          description={card.description}
        />
      ))}</div><Footer />
    </div>
  );
};

const CardComponent = ({
  isHovering,
  onMouseOver,
  onMouseOut,
  onClick,
  imgSrc,
  title,
  description,
}) => (
  <Card
    className="custom-card"
    style={{ backgroundColor: isHovering ? "#333" : "#fff" }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  >
    <div className="card-content">
      <img className="card-img" src={imgSrc} alt={title} />
      <div className="card-text">
        <h3 style={{ color: isHovering ? "#fff" : "#968375" }}>{title}</h3>
        <p style={{ color: isHovering ? "#fff" : "#968375" }}>{description}</p>
      </div>
    </div>
  </Card>
);

export default Branche;
