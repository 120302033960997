import React from "react";



import "../styles/Contact.css";
import Topbar from "../pages/Topbar";
import Posts from "./ContactsPosts";
function Contact() {
  return (

    <div>     <Topbar />
    <div >

    <Posts />

   
    </div>
  
    </div>
  );
}

export default Contact;