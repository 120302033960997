import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import DoorFittings from "../assets/images/Hardwares.jpg";
import  Door_handles from "../assets/images/hardware/Door_handles.JPG";
import Door_locks from "../assets/images/hardware/Door_locks.jpg";
import Tools from "../assets/images/hardware/hardwar.jpg";
import Hinges from "../assets/images/hardware/Hinges.JPG";
import Tower_Bolts from "../assets/images/hardware/Tower_Bolts.jpg";


import Topbar from "./Topbar";
import "../styles/Branche.css";
import Footer from "./Footer";
const cardData = [
  { id: 1, imgSrc: DoorFittings, title: "DOOR FITTINGS", description: "Door Fittings" },
  { id: 2, imgSrc:  Door_handles, title: "DOOR HANDLES", description: "Door handles" },
  { id: 3, imgSrc: Door_locks, title: "DOOR LOCKS ", description: "Door locks" },
  { id: 4, imgSrc: Tools, title: " TOOLS", description: "Tools" },
  { id: 5, imgSrc: Hinges, title: "HINGES", description: "Hinges" },
  { id: 6, imgSrc: Tower_Bolts, title: "TOWER BOLTS", description: "Tower Bolts" },
 ];

const Hardware = () => {
  const [hoveredCard, setHoveredCard] = useState(null);


  const handleMouseOver = (id) => setHoveredCard(id);
  const handleMouseOut = () => setHoveredCard(null);

  const handleClick = (id) => {
   // navigate(`/details/${id}`);
  };

  return (
    <div>
        <Topbar />
        <div  className="background-section"> 
      {cardData.map((card) => (
        <CardComponent
          key={card.id}
          isHovering={hoveredCard === card.id}
          onMouseOver={() => handleMouseOver(card.id)}
          onMouseOut={handleMouseOut}
          onClick={() => handleClick(card.id)}
          imgSrc={card.imgSrc}
          title={card.title}
          description={card.description}
        />
      ))}</div><Footer />
    </div>
  );
};

const CardComponent = ({
  isHovering,
  onMouseOver,
  onMouseOut,
  onClick,
  imgSrc,
  title,
  description,
}) => (
  <Card
    className="custom-card"
    style={{ backgroundColor: isHovering ? "#333" : "#fff" }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  >
    <div className="card-content">
      <img className="card-img" src={imgSrc} alt={title} />
      <div className="card-text">
        <h3 style={{ color: isHovering ? "#fff" : "#968375" }}>{title}</h3>
        <p style={{ color: isHovering ? "#fff" : "#968375" }}>{description}</p>
      </div>
    </div>
  </Card>
);

export default Hardware;
