import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import pvc_pipe from "../assets/images/Plumbing/pvc-pipe.jpg";
import  pipe_Fittings from "../assets/images/Plumbing/pipe_Fittings.jpg";
import epvc_pipes from "../assets/images/Pipe.png";
import WaterTanks from "../assets/images/Plumbing/WaterTanks.jpg";
import Chambers from "../assets/images/Plumbing/CHAMBERS.jpg";

import SepticTanks from "../assets/images/Plumbing/SepticTanks.jpg";


import Topbar from "./Topbar";
import "../styles/Branche.css";
import Footer from "./Footer";
const cardData = [
  { id: 1, imgSrc: pvc_pipe, title: "PVC PIPES AND FITTINGS ", description: "PVC  pipes & fittings" },
  { id: 2, imgSrc:  pipe_Fittings, title: "U PVC PIPE FITTINGS", description: "Upvc pipe-Fittings" },
  { id: 3, imgSrc: epvc_pipes, title: "E PVC PIPES & FITTINGS ", description: "E pvc pipes & Fittings" },
  { id: 4, imgSrc: Chambers, title: " CHAMBERS", description: "Chambers" },
  { id: 5, imgSrc: WaterTanks, title: "WATER TANKS", description: "Water Tanks" },
  { id: 6, imgSrc: SepticTanks, title: "SEPTIC TANKS", description: "Septic Tanks" },
 ];

const Plumbing = () => {
  const [hoveredCard, setHoveredCard] = useState(null);


  const handleMouseOver = (id) => setHoveredCard(id);
  const handleMouseOut = () => setHoveredCard(null);

  const handleClick = (id) => {
   // navigate(`/details/${id}`);
  };

  return (
    <div>
        <Topbar />
        <div  className="background-section"> 
      {cardData.map((card) => (
        <CardComponent
          key={card.id}
          isHovering={hoveredCard === card.id}
          onMouseOver={() => handleMouseOver(card.id)}
          onMouseOut={handleMouseOut}
          onClick={() => handleClick(card.id)}
          imgSrc={card.imgSrc}
          title={card.title}
          description={card.description}
        />
      ))}</div><Footer />
    </div>
  );
};

const CardComponent = ({
  isHovering,
  onMouseOver,
  onMouseOut,
  onClick,
  imgSrc,
  title,
  description,
}) => (
  <Card
    className="custom-card"
    style={{ backgroundColor: isHovering ? "#333" : "#fff" }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  >
    <div className="card-content">
      <img className="card-img" src={imgSrc} alt={title} />
      <div className="card-text">
        <h3 style={{ color: isHovering ? "#fff" : "#968375" }}>{title}</h3>
        <p style={{ color: isHovering ? "#fff" : "#968375" }}>{description}</p>
      </div>
    </div>
  </Card>
);

export default Plumbing;
