import React, { useState } from "react";

import Card from "react-bootstrap/Card";

import woodfinish from "../assets/images/paint/woodfinish.jpg";
import Textures from "../assets/images/paint/Textures.jpg";
import WALL from "../assets/images/paint/Wallpaint.jpg";
import MetalFinish from "../assets/images/paint/MetalFinish.jpeg";
import Adhesives from "../assets/images/paint/Adhesives.jpg";

import Topbar from "./Topbar";
import "../styles/Branche.css";
import Footer from "./Footer";
const cardData = [
  { id: 1, imgSrc: WALL, title: "WALL PAINT.", description: "WALL PAINT" },
  { id: 2, imgSrc: woodfinish, title: "WOOD FINISH", description: "wood finish" },
  { id: 3, imgSrc: Textures, title: "TEXTURE", description: "texture" },
  { id: 4, imgSrc: MetalFinish, title: "METAL FINISH", description: "Metal Finish" },
  { id: 5, imgSrc: Adhesives, title: "ADHESIVES", description: "Adhesives" },
  { id: 6, imgSrc: WALL, title: "WALLCARE PUTTY", description: "wallcare putty " },
  { id: 7, imgSrc: woodfinish, title: "SPAY PAINTS", description: "Spay paints" },
];

const Paint = () => {
  const [hoveredCard, setHoveredCard] = useState(null);


  const handleMouseOver = (id) => setHoveredCard(id);
  const handleMouseOut = () => setHoveredCard(null);

  const handleClick = (id) => {
   // navigate(`/details/${id}`);
  };

  return (
    <div>
        <Topbar />
        <div  className="background-section"> 
      {cardData.map((card) => (
        <CardComponent
          key={card.id}
          isHovering={hoveredCard === card.id}
          onMouseOver={() => handleMouseOver(card.id)}
          onMouseOut={handleMouseOut}
          onClick={() => handleClick(card.id)}
          imgSrc={card.imgSrc}
          title={card.title}
          description={card.description}
        />
      ))}</div><Footer />
    </div>
  );
};

const CardComponent = ({
  isHovering,
  onMouseOver,
  onMouseOut,
  onClick,
  imgSrc,
  title,
  description,
}) => (
  <Card
    className="custom-card"
    style={{ backgroundColor: isHovering ? "#333" : "#fff" }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onClick={onClick}
  >
    <div className="card-content">
      <img className="card-img" src={imgSrc} alt={title} />
      <div className="card-text">
        <h3 style={{ color: isHovering ? "#fff" : "#968375" }}>{title}</h3>
        <p style={{ color: isHovering ? "#fff" : "#968375" }}>{description}</p>
      </div>
    </div>
  </Card>
);

export default Paint;
