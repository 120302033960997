import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
import "../styles/Topbar.css"; // Import your custom CSS
import imgLog from "../assets/images/logo.png";

export default function Topbar() {
  const [isHidden, setIsHidden] = useState(false);
  const lastScrollY = useRef(window.pageYOffset); // Use useRef to persist lastScrollY value

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > lastScrollY.current) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      lastScrollY.current = window.pageYOffset; // Update the current value of lastScrollY
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg ${isHidden ? "d-none" : ""}`}
      style={{ 
        zIndex: '111111', 
        backgroundColor: '#80b3d8'
      }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img className="logos img-fluid" src={imgLog} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler ms-auto" // Added ms-auto to align to the right
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#topbarNav"
          aria-controls="topbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="topbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> {/* ms-auto to align items to the right */}
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/brands">
                BRANDS
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery">
                GALLERY
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
